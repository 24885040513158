.form {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.formFooter {
  display: flex;
}
.form hr {
  margin: 0;
  margin-top: 2em;
}
.inputField {
  display: flex;
  padding-bottom: 2em;
  flex-direction: column;
  width: 100%;
}
.inputField label {
  padding-bottom: 0.5em;
}
.inputField input {
  background: white;
  color: black;
}
.formButton {
  background-color: blueviolet;
}

.inputsHorizontal {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.inputsHorizontalStart {
  display: flex;
  padding-bottom: 2em;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.deleteAccount {
  width: 100%;
  color: red;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 15px;
  cursor: pointer;
}

.inputsHorizontalStartNoPadding {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.inputsVertical {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  height: 100%;
  padding: 15px;
}
.description {
  font-size: 14px;
  color: grey;
}

.radioControlLabel {
  border: 1px solid lightgrey;
  border-radius: 5px;
  color: black;
  margin-left: 0;
  width: 100%;
  margin-bottom: 10px;
}
