.container {
  min-height: 100vh;
  min-width: 100vw;
  display: flex;
  background-color: #f9fafc;
}

.main {
  margin: auto;
  width: 100%;
  max-width: 600px;
  padding: 4em;
}

.box {
  padding: 2em;
  margin-top: 4em;
  margin-bottom: 4em;

  padding-top: 2.5em;
  padding-bottom: 2.5em;

  justify-content: center;
  display: flex;
  flex-direction: column;
}

.logoWrapper {
  margin: 0 auto 1em;
}

.bottomLogoWrapper {
  margin: 2em auto 1em;
}
